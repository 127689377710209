import {
  CreateManufacturerInput,
  createManufacturerMutation,
  deleteManufacturer,
  Manufacturer,
  ManufacturersData,
  ManufacturersOrderByEnum,
  ManufacturersQueryArgs,
  MANUFACTURERS_QUERY,
  SortOrderEnum,
  updateManufacturerMutation,
} from '@curvo/apollo'
import { Button, message, Pagination, Popconfirm, Table } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { useEffect, useState } from 'react'
import { ResizableHeader } from '../../../components/ResizableColumnHeader'
import {
  ColumnGenFunctionType,
  PageWrapper,
  PaginationContainer,
  RedA,
  SearchWrapper,
  StyledSearchInput,
} from './common'
import { EditManufacturerDrawer, EditMode } from './components/EditPanel/EditManufacturer'

export const Manufacturers = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [limitPerPage, setLimitPerPage] = useState(10)
  const [queryArgs, setQueryArgs] = useState<ManufacturersQueryArgs>({
    first: limitPerPage,
    skip: 0,
  })

  const [editManufacturer, setEditManufacturer] = useState<Manufacturer>()
  const [editMode, setEditMode] = useState<EditMode>(EditMode.edit)

  const [columns, setColumns] = useState<ColumnProps<Manufacturer>[]>([])
  useEffect(() => {
    const handleUpdateManufacturer = (record: Manufacturer) => {
      setEditManufacturer(record)
      setEditMode(EditMode.edit)
    }
    const handleDeleteManufacturer = (record: Manufacturer) => {
      deleteManufacturer({ id: record.id }, { refetchQueries: [{ query: MANUFACTURERS_QUERY, variables: queryArgs }] })
    }
    setColumns(columnsGen(handleUpdateManufacturer, handleDeleteManufacturer))
  }, [queryArgs])
  const handleUpdateColumnSize = (index: number) => (size: number) => {
    const nextCols = [...columns]
    nextCols[index].width = size
    setColumns(nextCols)
  }
  return (
    <PageWrapper>
      <ManufacturersData variables={queryArgs} fetchPolicy="network-only" selfHandleError>
        {({ data, error, loading }) => {
          if (error) {
            message.error(error.message)
          }
          const manufacturers = data && data.manufacturers ? data.manufacturers.edges.map(edge => edge.node) : []
          const totalPages = data && data.manufacturers ? data.manufacturers.metadata.total : 0
          return (
            <div>
              <SearchWrapper>
                <StyledSearchInput
                  placeholder="Search Manufacturer by name"
                  enterButton
                  onSearch={searchText => {
                    setQueryArgs(query => ({
                      ...query,
                      searchText,
                    }))
                  }}
                />
                <Button
                  type="primary"
                  icon="plus"
                  onClick={() => {
                    setEditManufacturer(dummyNewManufacturer())
                    setEditMode(EditMode.create)
                  }}>
                  New Manufacturer
                </Button>
              </SearchWrapper>
              <Table
                columns={columns.map((col, i) => ({
                  ...col,
                  onHeaderCell: () => ({ width: col.width, onResize: handleUpdateColumnSize(i) }),
                }))}
                bordered
                loading={loading}
                dataSource={manufacturers}
                pagination={false}
                rowKey={record => record.id}
                components={{ header: { cell: ResizableHeader } }}
                onChange={(_, __, sorter) => {
                  if (sorter.field) {
                    setQueryArgs({
                      ...queryArgs,
                      orderBy: sorter.columnKey ? (sorter.columnKey as ManufacturersOrderByEnum) : undefined,
                      direction: sorter.order === 'ascend' ? SortOrderEnum.Asc : SortOrderEnum.Desc,
                    })
                  }
                }}
              />
              <PaginationContainer>
                <Pagination
                  total={totalPages}
                  defaultCurrent={currentPage}
                  onChange={pageNumber => {
                    setCurrentPage(pageNumber)
                    setQueryArgs(query => ({
                      ...query,
                      first: limitPerPage,
                      skip: (pageNumber - 1) * limitPerPage,
                    }))
                  }}
                  showSizeChanger
                  onShowSizeChange={(_, size) => setLimitPerPage(size)}
                />
              </PaginationContainer>
              {editManufacturer && (
                <EditManufacturerDrawer
                  manufacturer={editManufacturer}
                  onCancel={() => setEditManufacturer(undefined)}
                  onSubmit={editing => {
                    if (editMode === EditMode.edit) {
                      updateManufacturerMutation({ input: editing })
                        .then(() => message.success('updated'))
                        .catch(e => message.error(e.message))
                    } else {
                      createManufacturerMutation(
                        {
                          input: editing as CreateManufacturerInput,
                        },
                        { refetchQueries: [{ query: MANUFACTURERS_QUERY, variables: queryArgs }] },
                      )
                        .catch(e => message.error(e.message))
                        .then(() => message.success('success'))
                    }
                    setEditManufacturer(undefined)
                  }}
                  visible={!!editManufacturer}
                  width={300}
                  editMode={editMode}
                  title={editMode === EditMode.edit ? `Editing ${editManufacturer.name}` : 'Create new Manufacturer'}
                />
              )}
            </div>
          )
        }}
      </ManufacturersData>
    </PageWrapper>
  )
}

const columnsGen: ColumnGenFunctionType<Manufacturer> = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 100,
    key: 'id',
    sorter: true,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 150,
    key: 'name',
    sorter: true,
  },
  {
    title: 'State',
    dataIndex: 'state',
    width: 50,
    key: 'state',
  },
  {
    title: 'City',
    dataIndex: 'city',
    width: 80,
    key: 'city',
  },
  {
    title: 'Ticker',
    dataIndex: 'ticker',
    width: 100,
    key: 'ticker',
  },
  {
    title: 'Action',
    width: 150,
    key: 'action',
    render: (_text: any, record: Manufacturer) => (
      <span>
        <Button type="link" onClick={() => onEdit(record)}>
          Edit
        </Button>
        <span> | </span>
        <Popconfirm
          title={`Are you sure you want to delete Manufacturer ${record.name}?`}
          okText="Yes"
          cancelText="No"
          onConfirm={() => onDelete && onDelete(record)}>
          <RedA>Delete</RedA>
        </Popconfirm>
      </span>
    ),
  },
]

const dummyNewManufacturer = (): Manufacturer => ({
  id: '',
  city: '',
  country: '',
  name: '',
  state: '',
  url: '',
  obsolete: false,
})
